import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/ShapeShooter/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    slide: 'slide.mp3',
    hit: 'hit.mp3',
    correct: 'correct.mp3',
    incorrect: 'incorrect.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    modal: 'modal.webp'
  }
};

export default generateAssets(assetsDir, gameAssets);