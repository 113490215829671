//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';
import {Colors} from 'src/components/GamePlay/Common/data/Colors.js';


export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      /* new */
      randomSizes: [],
      randomSize: null,
      lastSize: null,
      sizeIndex: 0,
      settings: {},
      usedSizesForSymbol: [],
      selectedCategories: [],
      categoryMap: {
        sports: {index: 0, range: 6},
        shape: {index: 1, range: 7},
        animals: {index: 2, range: 6},
        leaves: {index: 3, range: 7},
        clothing: {index: 4, range: 7},
        vehicles: {index: 5, range: 6},
        candy: {index: 6, range: 7},
        fruit: {index: 7, range: 7}
      },
      categoryConfigMap: {
        sports: {index: 0, count: 7},
        shape: {index: 1, count: 8},
        animals: {index: 2, count: 7},
        leaves: {index: 3, count: 8},
        clothing: {index: 4, count: 8},
        vehicles: {index: 5, count: 7},
        candy: {index: 6, count: 8},
        fruit: {index: 7, count: 8}
      },
      uniqueSizes: [],
      paddles: [],
      colorIndex: 0,
      usedColorsForSymbol: [],
      shuffledSymbolSizesArrays: [],
      expectedAnswerMappings: {},
      paddlesCount: 0,
      /* old */
      activeButtons: [],
      answerRowResults: {},
      buttonReaction: null,
      buttonReactionClass: null,
      buttons: [],
      buttonsLength: 24,
      container: null,
      correctThisRound: 0,
      correctThisTrial: 0,
      hintMode: true,
      hintedButton: [],
      incorrectAnswers: false,
      showTaskHint: false,
      nextTrial: false,
      paddle: null,
      paddleTimer: null,
      playDisabled: false,
      playStart: null,
      endRoundText: '',
      totalAnswers: 0,
      totalAnswersPerTrial: 1,
      displaysPerItem: 1,
      amountOfObjects: 1,
      displaySizes: 1,
      wrongOrder: false,
      selectedAnswerIndex: null,
      lastSymbol: null,
      lastSymbolDisplayCount: 0,
      gameValues: {
        correct_score: 100,
        incorrect_score: 50,
        early_paddle_score: 5
      },
      symbolSizes: [
        30, 70, 175
      ],
      gamedata: {},
      expectedAnswers: [],
      task: false,
      expectedAnswersPerTrial: 0,
      totalExpectedAnswers: 0,
      colors: Colors.map(item => item.name),
      currentQuestionIndex: 0,
      endTrialTitle: '',
      isShowEndTrialDialog: false,
      positions: ["back", "mid", "front"],
      trial_tasks: ["size", "color", "category"],
      categories: ["sports", "shape", "animals", "leaves", "clothing", "vehicles", "candy", "fruit"],
      symbols: [
        {
          name: "sports",
          items: ["basketball", "baseball", "football", "volleyball", "soccerball", "golf", "badminton", "pool"]
        },
        {
          name: "shape",
          items: ["circle", "diamond", "square", "triangle", "star", "oval", "invert_triangle", "heart"]
        },
        {name: "animals", items: ["dolphin", "elephant", "fish", "lizard", "rabbit", "snail", "bird"]},
        {
          name: "leaves",
          items: ["leaf-1", "leaf-2", "leaf-3", "leaf-4", "leaf-5", "leaf-6", "leaf-7", "leaf-8"]
        },
        {
          name: "clothing",
          items: ["shirt", "pants", "hat", "sunglasses", "boot", "high-heel", "sneaker", "blouse"]
        },
        {name: "vehicles", items: ["plane", "boat", "bicycle", "van", "motorcycle", "train", "trash-truck"]},
        {
          name: "candy",
          items: ["wrapped-candy", "life_saver", "gummy_bear", "candy_corn", "lollypop", "gingerbread", "candy_square", "candy_corn"]
        },
        {
          name: "fruit",
          items: ["apple", "banana", "grape", "orange1", "strawberry", "cherry", "raspberry", "pineapple"]
        }
      ],

      // UI relation
      bottomPaddle: 0,
      imagePaddle: 'blue',
      imageSymbol: 'apple',
      paddleHeight: '80px',
      paddleTopMargin: '50px',
      paddleWidth: '80px',
      zIndexPaddle: -1,
      paddleClass: {
        "shapeshooter__paddle": true
      },

      intervals: {
        paddleTimer: null
      },
      currentIndex: -1
    };
  },
  methods: {
    toggleMode() {
      this.currentIndex = (this.currentIndex + 1) % this.trial_tasks.length;
      this.setGameMode(this.trial_tasks[this.currentIndex]);
      this.setTextPlayHints(this.gameMode === 'category' ? 'GROUP' : this.gameMode);
    },
    /**
     * @method initializeGamePlay
     * @description Initialize the game play variables
     * @returns {Promise<void>}
     */
    async initializeGamePlay(isFirstPlay = true) {
      // don't let them play until we get past the instruction modal
      if (isFirstPlay) {
        this.setGameExited(false);
        this.setTimer('false');
        this.setScore(0);
        this.container = this.$refs.gameplay;
        this.paddle = this.$refs.paddle;
      }

      this.playDisabled = true;
      this.incorrectAnswers = false;
      this.zIndexPaddle = -1;
      // this.setTextPlayHints(this.gameMode === 'cat' ? 'GROUP' : this.gameMode);
      // reveal selected buttons for this session
      // loop for each round
      // get the user level, setup grid for first trial/round
      await this.getSettings(isFirstPlay); // this will actually get the user, grid data
      await this.animation.to(this.container, 2.5, {y: 0, ease: this.tweenExpo.easeOut});

      await this.showGameplayHints();
      await this.sleep(9999999); // wait
    },
    /**
     * @method getSettings
     * @description Get the game settings from the API
     * @returns {Promise<unknown>}
     */
    async getSettings(firstPlay = true) {
      let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });

        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);

        if (firstPlay) {
          this.setLevel(this.gameLevelData.start_level);

          this.setRounds(5);
          this.setTrials(2);
        }
    },
    /**
     * @method startGamePlayMode
     * @description Start the game play mode
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      this.playDisabled = true;
      this.modalMessageReset();
      // show them a popup pointing out the task (color, size, category) in the HUD
      this.showTaskHint = true;
      this.setTextPlayHints(this.gameMode === 'category' ? 'GROUP' : this.gameMode);
      await this.sleep(2000); // wait
      this.showTaskHint = false;
      this.playStart = new Date().getTime(); // set the play start time
      console.log('►► playStart', this.playStart);
      this.setMusic(this.assets.audio.gamePlay, true);


      // really all that should happen here is that we start the timer. we should also
      // react to timer being done
      this.playDisabled = false;
      this.generatePaddles();
      this.displayInterval = this.getRandomFloatInRange(this.gameLevelData.game_settings.display_interval_min, this.gameLevelData.game_settings.display_interval_max);

      // start the movePaddle loop

      await this.movePaddle();
    },
    /**
     * Creates the paddles for the current game mode.
     * @returns {[]|*[]}
     */
    generatePaddles() {
      this.settings = this.gameLevelData.game_settings;
      // need to normalize this name
      this.settings.query_items_category = this.settings.query_cats_cat;

      this.displaysPerItem = this.getRandomIntegerInRange(
          this.settings.min_displays_per_item,
          this.settings.max_displays_per_item
      );

      this.resetGameState();
      this.resetSizes();
      this.resetColors();

      this.paddlesCount = this.calculateTotalPaddles();
      console.log('paddlesCount', this.paddlesCount);
      // Get a single random color or symbol for the respective game modes
      const randomColor = this.getRandomColor();

      const uniqueSymbols = this.getUniqueSymbols();
      const paddles = Array.from({length: this.paddlesCount}, (_, i) => {
        let size, color;

        const symbolIndex = i % uniqueSymbols.length;
        const symbol = uniqueSymbols[symbolIndex];
        const positionIndex = this.getRandomIntegerInRange(0, 2);

        if (this.gameMode === 'size') {
          size = this.getSize(symbol); // Pass the symbol to getSize
          color = randomColor;
        } else if (this.gameMode === 'color') {
          color = this.getColor(symbol);
          size = 70; // Pass the symbol to getSize
        } else if (this.gameMode === 'category') {
          color = randomColor;
          size = 70;
        }

        return {
          id: i + 1,
          symbol,
          size,
          color,
          position: this.positions[positionIndex]
        };
      });

      this.paddles = this.shufflePaddlesAndUpdateIds(paddles);
      this.expectedAnswers = this.getLastIterationOfSymbols();

      this.expectedAnswersPerTrial = this.expectedAnswers.length;
      this.totalExpectedAnswers += this.expectedAnswersPerTrial;

      this.initExpectedAnswerMappings();

    },
    getRandomColor() {
      return this.colors[this.getRandomIntegerInRange(0, this.colors.length - 1)];
    },
    calculateTotalPaddles() {
      const propertiesMap = {
        'size': 'display_sizes',
        'color': 'display_colors',
        'category': 'display_items'
      };
      const property = propertiesMap[this.gameMode];

      console.log('property', property, this.settings[property], this.displaysPerItem, this.settings[`query_items_${this.gameMode}`])
      return this.settings[`query_items_${this.gameMode}`] * this.settings[property] * this.displaysPerItem;
    },
    /**
     * Retrieves unique symbols based on the current game mode.
     * In 'category' mode, selects a specified number of symbols from each category.
     * For other game modes, selects symbols based on a different logic.
     *
     * @returns {Array} An array of symbols based on the game mode and settings.
     */
    getUniqueSymbols() {
      if (this.gameMode === 'category') {
        const numberOfCategories = this.settings.query_items_category;
        const itemsPerCategory = parseInt(this.settings.display_items, 10);

        // Shuffle the symbols array
        this.shuffleArray(this.symbols);

        // Select the first 'numberOfCategories' categories
        const selectedCategories = this.symbols.slice(0, numberOfCategories);
        this.selectedCategories = selectedCategories;
        this.selectedCategories = this.selectedCategories.map(category => category.name);
        // Transform selected categories into an array of selected symbols
        return selectedCategories.flatMap(category => category.items.slice(0, itemsPerCategory));

      } else {

        const querySize = this.getQuerySize();
        const randomCategoryIndex = this.getRandomIntegerInRange(0, this.categories.length - 1);
        const randomCategoryName = this.categories[randomCategoryIndex];
        const categorySymbols = this.symbols.find(category => category.name === randomCategoryName).items;

        if (querySize === 1) {
          return [categorySymbols[this.getRandomIntegerInRange(0, categorySymbols.length)]];
        }

        let symbolIndices = Array.from({length: categorySymbols.length}, (_, i) => i);
        this.shuffleArray(symbolIndices);

        return symbolIndices.slice(0, querySize)
            .map(index => categorySymbols[index]);
      }
    },
    shufflePaddlesAndUpdateIds(paddles) {
      // Assuming shuffleArray is a method that shuffles the paddles array
      return this.shuffleArray(paddles).map((paddle, index) => ({...paddle, id: index + 1}));
    },
    getSize(symbol) {
      if (this.gameMode === 'size') {
        // Initialize a map to keep track of used sizes for each symbol
        this.usedSizesForSymbol = this.usedSizesForSymbol || {};

        // Initialize the array for the symbol if it doesn't exist
        if (!this.usedSizesForSymbol[symbol]) {
          this.usedSizesForSymbol[symbol] = [];
        }

        // Select the next size for the symbol
        let nextSizeIndex = this.usedSizesForSymbol[symbol].length % this.uniqueSizes.length;
        let nextSize = this.uniqueSizes[nextSizeIndex];

        // Add the size to the used sizes for the symbol
        this.usedSizesForSymbol[symbol].push(nextSize);

        // Reset the used sizes for the symbol if all unique sizes have been used
        if (this.usedSizesForSymbol[symbol].length === this.uniqueSizes.length) {
          this.usedSizesForSymbol[symbol] = [];
        }

        return nextSize;
      } else {
        // Return the current size and advance the index.
        let currentValue = this.uniqueSizes[this.sizeIndex];
        if (typeof currentValue === 'undefined') {
          // Log an error if currentValue is undefined
          console.error('Undefined size value at index:', this.sizeIndex, 'uniqueSizes:', this.uniqueSizes);
          this.resetSizes();
          currentValue = this.uniqueSizes[this.sizeIndex]; // Attempt to recover
        }
        this.sizeIndex = (this.sizeIndex + 1) % this.uniqueSizes.length;

        return currentValue;
      }
    },
    getColor(symbol) {
      // Initialize a map to keep track of used colors for each symbol
      this.usedColorsForSymbol = this.usedColorsForSymbol || {};

      // Initialize the array for the symbol if it doesn't exist
      if (!this.usedColorsForSymbol[symbol]) {
        this.usedColorsForSymbol[symbol] = [];
      }

      // Select the next color for the symbol
      let nextColorIndex = this.usedColorsForSymbol[symbol].length % this.uniqueColors.length;
      let nextColor = this.uniqueColors[nextColorIndex];

      // Add the color to the used colors for the symbol
      this.usedColorsForSymbol[symbol].push(nextColor);

      // Reset the used colors for the symbol if all unique colors have been used
      if (this.usedColorsForSymbol[symbol].length === this.uniqueColors.length) {
        this.usedColorsForSymbol[symbol] = [];
      }

      return nextColor;
    },
    getLastIterationOfSymbols() {
    const lastSymbolsMap = new Map();
    const lastIterationBreak = this.getQuerySize();

    const symbolToCategoryMap = this.createSymbolToCategoryMap();

    if (this.gameMode === 'category') {
      return this.handleCategoryGameMode(lastSymbolsMap, lastIterationBreak, symbolToCategoryMap);
    } else if (this.gameMode === 'size' || this.gameMode === 'color') {
      return this.handleSizeOrColorGameMode(lastSymbolsMap, lastIterationBreak, symbolToCategoryMap);
    }
  },

  createSymbolToCategoryMap() {
    const symbolToCategoryMap = new Map();

    this.symbols.forEach(category => {
      category.items.forEach(symbol => {
        symbolToCategoryMap.set(symbol, category.name);
      });
    });

    return symbolToCategoryMap;
  },

  handleCategoryGameMode(lastSymbolsMap, lastIterationBreak, symbolToCategoryMap) {
    let uniqueCategoriesCount = 0;

    for (let i = this.paddles.length - 1; i >= 0; i--) {
      const paddle = this.paddles[i];
      const category = symbolToCategoryMap.get(paddle.symbol);

      if (!lastSymbolsMap.has(category) && uniqueCategoriesCount < lastIterationBreak) {
        lastSymbolsMap.set(category, {paddle: paddle, category: category});
        uniqueCategoriesCount++;
      }

      if (uniqueCategoriesCount === lastIterationBreak) break;
    }

    const lastIterations = Array.from(lastSymbolsMap.values());

    return lastIterations.sort((a, b) => a.paddle.id - b.paddle.id);
  },

  handleSizeOrColorGameMode(lastSymbolsMap, lastIterationBreak, symbolToCategoryMap) {
    for (let i = this.paddles.length - 1; i >= 0; i--) {
      const paddle = this.paddles[i];
      const category = symbolToCategoryMap.get(paddle.symbol);

      if (!lastSymbolsMap.has(paddle.symbol)) {
        lastSymbolsMap.set(paddle.symbol, {paddle: paddle, category: category});
      }
      if (lastSymbolsMap.size === lastIterationBreak) break;
    }

    return Array.from(lastSymbolsMap.values()).sort((a, b) => a.paddle.id - b.paddle.id);
  },
    resetGameState() {
      this.lastSymbol = null;
      this.lastSymbolDisplayCount = 0;
      // ...reset any other relevant state properties
    },
    /**
     * Reset the sizes array and size index.
     * This method is called when the game mode is changed.
     * @returns {void}
     */
    resetSizes() {
      // Shuffle the sizes and store only as many unique sizes as needed.
      this.uniqueSizes = [];
      let shuffledSizes = [...this.symbolSizes].sort(() => 0.5 - Math.random()); // Ensure proper shuffling
      this.uniqueSizes = shuffledSizes.slice(0, this.settings.display_sizes);
      this.sizeIndex = 0;

    },
    /**
     * Reset the colors array and color index.
     * This method is called when the game mode is changed.
     * @returns {void}
     */
    resetColors() {
      // Ensure that there's a color array to work with.
      if (!this.colors || !Array.isArray(this.colors) || this.colors.length === 0) {
        console.error('No colors array is defined.');
        return;
      }

      // Shuffle and store only as many unique colors as needed.
      let shuffledColors = [...this.colors].sort(() => 0.5 - Math.random());
      this.uniqueColors = shuffledColors.slice(0, this.settings.display_colors);
      this.colorIndex = 0;
    },
    /**
     * Get the query size based on the current game mode.
     * @returns {number}
     */
    getQuerySize() {
      let querySize = 0;
      if (this.gameMode === "size") {
        querySize = this.settings.query_items_size;
      } else if (this.gameMode === "color") {
        querySize = this.settings.query_items_color;
      } else if (this.gameMode === "category") {
        querySize = this.settings.query_items_category; //uniqueSymbols.length
      }

      return querySize;
    },
    /**
     * @method movePaddle
     * @description Move the paddle to the next stimuli
     * @returns {Promise<void>}
     */
    async movePaddle() {
      try {
        if (this.playDisabled) return;

        // If we're outside the bounds of the paddles array, show the end trial dialog
        if (this.currentQuestionIndex >= this.paddles.length) {

          this.showEndTrialDialog();
          // console.log just the filtered size properties from this.paddles which is an array of objects
          console.log('paddles', this.paddles.map(paddle => paddle.size));
          this.playDisabled = false;
          return;
        }

        const paddle = this.paddles[this.currentQuestionIndex];
        this.currentQuestionIndex += 1;

        // Set paddle styles
        const sizeStyles = {
          30: {paddleTopMargin: '65px', paddleHeight: '40px', paddleWidth: '40px'},
          70: {paddleTopMargin: '50px', paddleHeight: '70px', paddleWidth: '70px'},
          175: {paddleTopMargin: '12px', paddleHeight: '175px', paddleWidth: '175px'}
        };

        const selectedStyle = sizeStyles[paddle.size];
        Object.assign(this, selectedStyle);

        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const xDistance = this.getRandomIntegerInRange(100, 200);
        const fromLeftToRight = this.getRandomDirection();  // get random direction

        // Animate the paddle
        await this.animatePaddle(paddle, width, xDistance, fromLeftToRight);


        // Recursive call with a delay
        setTimeout(() => this.movePaddle(), this.displayInterval);
      } catch (error) {
        console.error('Error in movePaddle:', error);
      }
    },
    getRandomDirection() {
      return Math.random() < 0.5;
   },
    async animatePaddle(paddle, width, xDistance, fromLeftToRight) {
      await this.animation.to(this.paddle, 0.3, {
        y: this.bottomPaddle,
        x: fromLeftToRight ? width / 4 + xDistance : width - (width / 4 + xDistance),
        ease: this.tweenExpo.ease
      });
      this.imageSymbol = paddle.symbol;
      this.imagePaddle = paddle.color;

      await this.animation.to(this.paddle, 0.5, {opacity: 1, ease: "bounce"});
      this.setEffect(this.assets.audio.slide);

      const positionSettings = {
        back: {zIndex: 1, bottom: 200},
        mid: {zIndex: 3, bottom: 350},
        front: {zIndex: 5, bottom: 500}
      };
      const setting = positionSettings[paddle.position];
      this.zIndexPaddle = setting.zIndex;
      this.bottomPaddle = setting.bottom;

      await this.animation.to(this.paddle, 0.5, {y: this.bottomPaddle - 350, ease: this.tweenExpo.easeOut});

      if(fromLeftToRight) {
        await this.animation.to(this.paddle, this.displayInterval, {x: width / 4 + 400 + xDistance, ease: this.tweenExpo.easeOut});
      } else {
        await this.animation.to(this.paddle, this.displayInterval, {x: width - (width / 4 + 400 + xDistance), ease: this.tweenExpo.easeOut});
      }

      await this.animation.to(this.paddle, 0.5, {y: this.bottomPaddle, ease: this.tweenExpo.easeOut});
    },
    /**
     * @method showEndTrialDialog
     * @description Show the end trial dialog
     */
    showEndTrialDialog() {
      this.selectedCategories = [];
      this.isShowEndTrialDialog = true;

      const titles = {
        size: 'Click the LAST SIZE you saw for each shape?',
        color: 'Click the LAST COLOR you saw for each shape!',
        category: 'What was the last target from each group?'
      };

      this.endTrialTitle = titles[this.gameMode]; // Provide a default if necessary
    },
    /**
     * @method showGameplayHints
     * @description Show the gameplay hints for this trial
     * @returns {Promise<void>}
     */
    async showGameplayHints() {
      // show hints dependent on Level
      if (this.gameExited) {
        this.unloadMusic();
        return; // make sure if the user exits at this stage, we don't keep going
      }
      await this.startGamePlayMode();
    },
    /**
     * @method determineNextGamePhase
     * @description Determine whether to start new round, trial, or end game
     * @returns {void}
     */
    async determineNextGamePhase() {
      this.answerRowResults = {};
      await this.setGameLevelAndSaveTrialResults();
      this.createShuffledSymbolSizesArray();
      console.log('determineNextGamePhase');
      this.stopMusic();
      if (!this.gamePlayComplete) {
        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        await this.handleGamePlayComplete();
      }
    },
    /**
     * @method getRoundScoreAndGoToNext
     * @description Get the round score and go to the next round
     * @returns {void}
     */
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    /**
     * @method goToNextTrial
     * @description Go to the next trial depending on the current trial and round
     * @returns {void}
     */
    async goToNextTrial() {
      console.log('geToNextTrial');
      this.currentQuestionIndex = 0;
      this.playDisabled = true;
      
      this.modalMessageReset();

      await this.sleep(2400); // so we see stuff before it goes away?
      this.playDisabled = false;
      this.startNewRound();
      if (this.currentTrial === 1) {
        this.resetTrialsCorrect();
        this.clearTrialStatus();
        this.totalExpectedAnswers = 0;
      }

      this.setTimer('false');
      this.playStart = new Date().getTime(); // start counting the next trial time
      this.correctThisTrial = 0;

      await this.initializeGamePlay(false);
    },
    /**
     * @method endRoundScore
     * @description Calculate the score based on the number of correct answers
     * @returns {Promise<void>}
     */
    async endRoundScore() {
      console.log('▶▶ endRoundScore');

      if (this.allAnswersCorrect) {
        this.goUpLevel();
        this.increaseTickets(3);
        this.setTicketEffect();
        console.log('▶ level Up');
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('▶ no level up');
      }

      if (this.noAnswersCorrect) {
        console.log('▶ level Down');
        this.goDownLevel();
      }

      this.incorrectAnswers = false;
      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
    },
    /**
     * @method goToNextRound
     * @description Go to the next round
     * @returns {Promise<void>}
     */
    async goToNextRound() {
      this.correctThisTrial = 0;
      console.log('goToNextRound');

      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      }

      if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      }

      if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      if (!this.gamePlayComplete) {
        this.setModalMessage({
          title: this.formatText(
              gamePlayCopy.rounds.end,
              {round: this.currentRound},
              false
          ),
          body: this.formatText(this.endRoundText, {correct: Math.min(this.trialsCorrect, this.trials)}),
          actionButton: {
            action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
            text: gamePlayCopy.rounds.playNext
          }
        });
      }
    },
    /**
     * @method handleGamePlayComplete
     * @description The game play is complete. Show the end of game modal and update the play level
     * @returns {void}
     */
    async handleGamePlayComplete() {
      console.log('game play is complete');
      this.stopMusic();
      await this.endRoundScore();

      this.playDisabled = true;
      this.increaseTickets(6);
      this.setTicketEffect();
      this.setEffect(this.assets.audio.gameEnd);
      await this.updatePlayLevel({
        gameId: this.gameId, play_id: this.play_id, level: Math.max(1, this.level), tickets: this.tickets, stopTime: new Date().getTime()
      });

      this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
    },
    /**
     * @method paddleAction
     * @description The paddle action is the action that happens when the paddle is hit
     * @param event
     * @returns {Promise<void>}
     */
    async paddleAction() {
      if (this.playDisabled) return;

      this.animation.clear();
      this.increaseScore(this.gameValues.early_paddle_score);
      this.setEffect(this.assets.audio.hit);
      await this.animation.to(this.paddle, 1, {
        rotation: -90,           // rotate by 360 degrees
        transformOrigin: "50% 100%", // rotate from the bottom center of the element
        duration: 1,             // duration of 1 second
        ease: "bounce"           // easing
      });
      await this.animation.to(this.paddle, 0.1, {opacity: 0, ease: "bounce"});
      // reset the paddle rotation
      await this.animation.to(this.paddle, 0.1, {
        rotation: 0,           // rotate by 360 degrees
        transformOrigin: "50% 100%", // rotate from the bottom center of the element
        duration: 0.1,             // duration of 1 second
        ease: "bounce"           // easing
      });
      setTimeout(() => this.movePaddle(), this.displayInterval);
    },
    /**
     * @method symbolClickHandler
     * @description Handle the answer click
     * @param index
     * @param response
     * @returns {Promise<void>}
     */
    async symbolClickHandler(index, response) {
      if (this.playDisabled) return;
      this.playDisabled = true;
      const symbol = this.expectedAnswers[index];
      let isCorrect;

      switch (this.gameMode) {
        case 'color':
          isCorrect = symbol.paddle.color === response;
          break;
        case 'category':
          isCorrect = symbol.paddle.symbol === response;
          break;
        case 'size':
          isCorrect = symbol.paddle.size === response;
          break;
      }

      this.$set(this.answerRowResults, index, isCorrect ? 'correct' : 'incorrect');

      const scoreModifier = isCorrect ? this.gameValues.correct_score : this.gameValues.incorrect_score;
      this.setEffect(this.assets.audio[isCorrect ? 'correct' : 'incorrect']);

      if (isCorrect) {
        this.correctThisTrial++;
        this.increaseScore(scoreModifier);
      } else {
        this.incorrectAnswers = true;
        this.decreaseScore(scoreModifier);
      }

      this.selectedAnswerIndex = index;
      await this.sleep(600);
      delete this.answerRowResults[index];
      await this.sleep(500);

      this.expectedAnswers.splice(index, 1);
      this.updateExpectedAnswerMappings(index);
      this.selectedAnswerIndex = null;
      this.playDisabled = false;
      this.hideAnswerDialog();
    },
    /**
     * @method hideAnswerDialog
     * @description Hide the answer dialog and bump up correctThisRound if all answers are correct
     */
    hideAnswerDialog() {

      if (this.expectedAnswers.length === 0) {
      if (this.expectedAnswersPerTrial === this.correctThisTrial) {
          this.increaseTrialsCorrect();
          this.correctThisRound++;
          this.setTrialStatus('correct');
        this.trialWasCorrect = true;
        } else {
          this.setTrialStatus('incorrect');
        this.trialWasCorrect = false;
        }
        this.isShowEndTrialDialog = false;
        this.determineNextGamePhase();
      }
    },
    /**
     * @method createShuffledSymbolSizesArray
     * @description Create a shuffled array of symbol sizes to avoid binding issues in loops
     */
    createShuffledSymbolSizesArray(){
      for(let i = 0; i < 50; i++){
        this.shuffledSymbolSizesArrays.push(this.shuffleArray(this.symbolSizes, true));
      }
    },
    /**
     * @method initExpectedAnswerMappings
     * @description Initialize the expected answer mappings
     */
    initExpectedAnswerMappings() {
      this.expectedAnswerMappings = {};
      this.expectedAnswers.forEach((item, index) => {
        this.expectedAnswerMappings[index] = index;
      });

      console.log('expectedAnswerMappings', this.expectedAnswerMappings);
    },
    updateExpectedAnswerMappings(removedIndex) {
      const mappings = {...this.expectedAnswerMappings};
      delete mappings[removedIndex];
      this.expectedAnswerMappings = Object.values(mappings).reduce((acc, curr, index) => {
        return {...acc, [index]: curr};
      }, {});
    },
    getSymbolsByCategory(name) {
      // Find the object with the matching name
      const matchingObject = this.symbols.find(obj => obj.name === name);
      // Check if a matching object was found
      if (matchingObject) {
        // Return the items array from the found object
        return matchingObject.items;
      } else {
        // Return an empty array if no matching object was found
        return [];
      }
    },
    randomizedPaddleSizes() {
      return this.symbolSizes.sort(() => 0.5 - Math.random());
    }
  },
  computed: {
    allAnswersCorrect() {
      // if this.trialStatus array does not contain any 'incorrect' values, then all answers are correct
      return !this.trialStatus.includes('incorrect');
    },
    someAnswersCorrect() {
      return this.trialStatus.includes('incorrect') && this.trialStatus.includes('correct');
    },
    noAnswersCorrect() {
      return this.trialStatus.includes('incorrect') && !this.trialStatus.includes('correct');
    }
  },
  created() {
    this.createShuffledSymbolSizesArray();
  },
  async mounted() {
    await this.initializeGamePlay(); // start round 1, trial 1
  },
  beforeDestroy() {
    // clear the timers before exiting to make sure it doesn't keep running
    Object.keys(this.intervals).forEach(key => {
      clearTimeout(this.intervals[key]);
    });
  }
};
